<template>
  <div class="page-wrapper-pc">
    <div class="container">
      <div class="flex-between margin-bottom-24 padding-top-48">
        <div class="size-36 weight-600">서비스 개설 신청</div>
        <box-step :start-value="2" :end-value="2" background-color="#fafafa"></box-step>
      </div>

      <div class="service-info-box1">
        <div class="size-18 weight-600 margin-bottom-32">결제 정보</div>
        <div class="flex-between">
          <div>·총 서비스 제작 금액</div>
          <div>{{ service.price | currency }}</div>
        </div>
        <div class="flex-between">
          <div>·VAT 10% 별도</div>
          <div>{{ (service.price/10) | currency }}</div>
        </div>

      </div>
      <div class="position-relative" style="padding:2px 26px;background-color:white">
        <div class="divide-dashed"></div>
        <div class="left-rotate-rectangle"></div>
        <div class="right-rotate-rectangle"></div>
      </div>
      <div class="service-info-box2">
        <div class="flex-between">
          <div class="size-18 weight-600">총 서비스 개설 금액</div>
          <div class="size-32 weight-600">{{ (service.price + service.price/10) | currency }}</div>
        </div>
      </div>


      <lp-check-bool class="margin-top-12" :checkedVal.sync="checkedVal" style="margin-top:24px"
                     label="구매 정보 및 결제 내역을 확인했으며, 이에 동의합니다."></lp-check-bool>

      <div style="margin-top:80px">
        <div class="size-18 weight-600">결제 방법</div>
        <div class="flex-between margin-top-24">
          <div class="flex-align">
            <button class="button weight-600" :class="{'is-primary': payMethod==='credit', 'is-background': payMethod!=='credit'}"
                    @click="clickPayMethod('credit')">신용카드</button>
            <button class="button weight-600 margin-left-12"
                    :class="{'is-primary': payMethod==='bank', 'is-background': payMethod!=='bank'}"
                    @click="clickPayMethod('bank')">무통장입금</button>
          </div>
          <!--<div>
            <button class="button is-background flex-align" style="padding:12px">
              <i class="material-icons size-18 margin-right-8">help_outline</i>
              <span class="weight-600">무이자 할부 안내</span>
            </button>
          </div>-->
        </div>
        <div v-if="payMethod==='bank'" style="margin-top:8px">*무통장 입금의 경우 18시 이전의 결제건에 대해서만 당일 개설이 가능합니다.</div>
      </div>

      <!--<div style="margin-top:80px">
        <div class="size-18 weight-600 margin-bottom-24">정기 결제 안내</div>
        <div class="flex-align">
          <div class="payment-info-box flex position-relative">
            <div class="payment-item" v-for="(item, idx) in 2" :key="`item-${idx}`">
              <div class="size-18 weight-600" v-if="idx===0">{{ now.format('YYYY-MM-DD') }}</div>
              <div class="size-18 weight-600" v-else>{{ now.add(1, 'months').format('YYYY-MM-DD') }}</div>
              <div class="flex-between margin-top-24">
                <div>·호스팅 비</div>
                <div>{{ 55000 | currency }}</div>
              </div>

              <div class="flex-between size-18 weight-600" style="margin-top:80px">
                <div>총 금액</div>
                <div>{{ 55000 | currency }}</div>
              </div>
            </div>

            <div class="dash-wrapper-vertical">
              <div class="dashed"></div>
              <div class="top-rotate-rectangle"></div>
              <div class="bottom-rotate-rectangle"></div>
            </div>
          </div>

        </div>

      </div>-->

      <div class="flex-end margin-top-36 padding-bottom-56">
        <button class="button is-primary margin-left-12"
                style="width:200px"
                @click="clickDone">결제하기</button>
      </div>
    </div>
  </div>
</template>
<script>

  import BoxStep from "../../component/BoxStep";
  import LpCheckBool from "../../module/LpCheckBool";
  export default {
    name: "ServiceRequestStep2",
    components: {LpCheckBool, BoxStep},
    props: {
      service: {
        type: Object
      }
    },
    created() {
      this.now = this.dayjs();

    },
    data() {
      return {
        payMethod: 'credit',
        now: '',
        checkedVal: false
      }
    },
    methods: {
      clickPayMethod(item) {
        /*if(item === 'bank') {
          this.toast('현재는 신용카드로만 결제 하실 수 있습니다');
        } else {
         this.payMethod = item;
        }*/

        this.payMethod = item;
      },
      clickDone() {
        if(!this.checkedVal) {
          this.toast('구매 정보 및 결제 내역 확인 및 동의에 체크해주세요');
          return;
        }
        this.service.payMethod = this.payMethod;
        this.$emit('done');
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .divide-dashed
    border-top 2px dashed $border2

  .left-rotate-rectangle
  .right-rotate-rectangle
    width 15px
    height 15px
    background-color $background
    transform rotate(45deg)
    position absolute

  .left-rotate-rectangle
    box-shadow inset -3px 3px 4px 0 rgba(0,0,0, 0.1)
    left -8px
    top -4px

  .right-rotate-rectangle
    box-shadow inset 3px -3px 4px 0 rgba(0,0,0, 0.1)
    right -8px
    top -4px

  .service-info-box1
  .service-info-box2
    background-color white
    padding 30px 26px

  .service-info-box1
    box-shadow 0 0 8px 5px rgba(0,0,0, 0.1)
  .service-info-box2
    box-shadow 0 3px 8px 3px rgba(0,0,0, 0.1)

  .payment-info-box
    background-color white

  .payment-info-box
    box-shadow 0 0 8px 5px rgba(0,0,0, 0.1)

  .payment-item
    width 300px
    padding 30px 26px

  .dash-wrapper-vertical
    position absolute
    top 0
    left 50%
    transform translate(-50%, 0)
    height 100%
    display flex
    align-items center
    .dashed
      border-right 2px dashed $border2
      height 77%

  .top-rotate-rectangle
  .bottom-rotate-rectangle
    width 15px
    height 15px
    background-color $background
    transform rotate(45deg)
    position absolute

  .top-rotate-rectangle
    box-shadow inset -3px -3px 4px 0 rgba(0,0,0, 0.1)
    left -7px
    top -8px

  .bottom-rotate-rectangle
    box-shadow inset 3px 3px 4px 0 rgba(0,0,0, 0.1)
    left -7px
    bottom -8px

</style>
